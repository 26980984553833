body {
  font-family: "Open Sans", sans-serif;
}

/* Header */

.jumbotron {
  font-family: "Special-Elite";
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  height: 500px;
}

.jumbotron-background {
  object-fit: cover;
  position:absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

.site-title {
  color: white;
}

img.blur {
	-webkit-filter: blur(4px);
	filter: blur(4px);
  filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='4');
}

.jumbotron h2 {
  color: #CEC8FF;
}

.navbar-dark {
  background-color: #2A1B3D;
}

.nav-container {
  display: flex;
  float: right;
}

/* Home */

.greeting {
  padding-top: 5rem;
  text-align: center;
}

.promo {
  padding-bottom: 5rem;
}

.promos {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10rem;
}

.promo-img {
  margin: auto;
  display: block;
}

.promo-description {
  text-align: center;
}

.book-of-the-month {
  display: flex;
  flex-wrap: wrap;
  padding-top: 5rem;
  padding-bottom: 10rem;
}

.book-of-the-month img {
  max-height: 400px;
  padding-left: 10rem;
}

.book-of-the-month p {
  text-align: justify;
  text-justify: inter-word;
}

.book-of-the-month h1 {
  text-align: center;
}

.current-book {
  display: flex;
  flex-wrap: wrap;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.current-book img {
  max-height: 400px;
  padding-right: 10rem;
}

.current-book p {
  text-align: justify;
  text-justify: inter-word;
}

.current-book h1 {
  text-align: center;
  padding-bottom: 1rem;
}

.current-book h3 {
  text-align: center;
}

.greeting h1 {
  text-align: center;
  cursor: pointer;
  position: relative;
  display: inline-block;
  font-size: 3rem;
  background: linear-gradient(to bottom, #000, #000 60%, #fff 60%, #fff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-repeat: no-repeat;
  transition: background 0.2s ease-out;
  white-space: nowrap;
}

.greeting h1:hover {
  background-position: 0 11px;
}

.greeting span {
  position: relative;
}

.greeting span:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 15px;
  background: #000;
  bottom: 9px;
  transition: all 0.2s ease-out;}

.greeting span:hover:before {
  transform: translateY(13px)
}

.book-of-the-month h3 {
  text-align: center;
}

/* Cards */

.card-title {
  font-weight: 500;
  font-size: 1.25rem;
}

.card-img-overlay .card-title {
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}

.row-content {
  padding: 50px 0;
  border-bottom: 1px ridge;
  min-height: 200px;
}

/* Partners */

.partners li {
  padding-bottom: 1rem;
}

/* Library */

.library-card {
  margin-left: 3rem;
  margin-bottom: 3rem;
}

.library-card img {
  padding-top: 5rem;
  padding-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.library-row {
  margin-top: 3rem;
  margin-bottom: 10rem;
}

.library-card-title {
  text-align: center;
}

.library-book-card img {
  width:  300px;
  height: 500px;
}

.library-row {
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

/* Footer */

.site-footer {
  height: 250px;
  padding-top: 60px;
}

#home-link {
  color: #2A1B3D;
}

#library-link {
  color: #2A1B3D;
}

#about-link {
  color: #2A1B3D;
}

#contact-link {
  color: #2A1B3D;
}

.footer-contact a {
  color: #2A1B3D;
}

.footer-contact a:hover {
  color: #2A1B3D;
}